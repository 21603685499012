import * as React from "react"
import { graphql } from "gatsby"
// Components
import { AiOutlineInfoCircle } from "react-icons/ai"
import SEO from "../components/seo"
import ContactUs from "../components/form/contact-us-form"
import useLocalizationKeys from "../components/localization-utils/useLocalizationKeys"
import useTranslation from "../components/localization-utils/useTranslation"
// Icons

const ContactUsPage: React.FC = ({ data }: any): JSX.Element => {
  const {
    contactUsPage: { title, subHeading, japaneseInfoText },
    SEO: s,
  } = useLocalizationKeys()

  const { hubspotInfo } = data.allMetaDataJson.nodes[0]

  /**
   * Determines if preferred language of user's browser is japansese
   */
  const language = typeof navigator !== "undefined" ? navigator.language : "en"
  const isUserJapaneseLanguage = language.toLowerCase().startsWith("ja")

  return (
    <>
      <SEO title={useTranslation(s.contactUs.title)} description={useTranslation(s.contactUs.description)} />
      <div className="page-header">
        <h1>{useTranslation(title)}</h1>
        <p>{useTranslation(subHeading)}</p>
        {isUserJapaneseLanguage && (
          <div className="info-message">
            <AiOutlineInfoCircle className="large-icon" />
            <p>{useTranslation(japaneseInfoText)}</p>
          </div>
        )}
      </div>
      <ContactUs hubspotInfo={hubspotInfo} />
    </>
  )
}

export default ContactUsPage

export const ContactUsPageData = graphql`
  query HubspotInfoQuery {
    allMetaDataJson {
      nodes {
        hubspotInfo {
          guid
          portalId
        }
      }
    }
  }
`
