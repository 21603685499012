import * as React from "react"
import {
  AiOutlineUser,
  AiOutlineMail,
  AiOutlineLaptop,
  AiOutlinePhone,
} from "react-icons/ai"

interface InputFieldProps {
  label: string
  placeholder: string
  val: string
  keyName: string
  error: boolean
  requiredFieldError: string
  setVal: (arg0: string) => any
  removeErrorMessage: (arg0: boolean) => any
}

const getIcon: (key: string) => React.ReactElement = function getIcon(
  key: string
): React.ReactElement {
  switch (key) {
    case "firstname":
      return <AiOutlineUser className="medium-icon" />
    case "company":
      return <AiOutlineLaptop className="medium-icon" />
    case "email":
      return <AiOutlineMail className="medium-icon" />
    case "phone":
      return <AiOutlinePhone className="medium-icon" />
    default:
  }
}

const InputField: React.FC<InputFieldProps> = ({
  label,
  setVal,
  val,
  placeholder,
  keyName,
  error,
  removeErrorMessage,
  requiredFieldError,
}: InputFieldProps): JSX.Element => {
  /**
   * updateVal is invoked when user changes input field. This removes any error message and saves the changed value in state.
   * @param event
   */
  const updateVal = (event: React.SyntheticEvent): void => {
    removeErrorMessage(false)
    setVal((event.target as HTMLTextAreaElement).value)
  }

  return (
    <>
      <div className="input-field-with-icon">
        {getIcon(keyName)}
        <input
          className={error ? "error-field" : ""}
          type="text"
          placeholder={placeholder}
          name={label}
          onChange={updateVal}
          value={val}
        />
      </div>
      {error && <p className="error-text">{requiredFieldError}</p>}
    </>
  )
}

export default InputField
