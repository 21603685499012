import * as React from "react"
// Icons
import { AiOutlineWarning, AiOutlineCheckCircle } from "react-icons/ai"
// Components
import InputField from "./label-input-field"
import TextAreaField from "./label-textarea-field"
import useLocalizationKeys from "../localization-utils/useLocalizationKeys"
import useTranslation from "../localization-utils/useTranslation"

enum ContactFormStatus {
  NONE,
  SENDING,
  SUCCESS,
  FAILURE,
}

interface ContactUsProps {
  hubspotInfo: HubspotInfo
}

interface HubspotInfo {
  portalId: string
  guid: string
}

const ContactUs: React.FC<ContactUsProps> = ({ hubspotInfo }): JSX.Element => {
  const {
    contactUsPage: {
      failureMessage,
      successMessage,
      name,
      message,
      email,
      phone,
      company,
      sendMessageBtnText,
      sendingMessageBtnText,
      requiredFieldError,
    },
  } = useLocalizationKeys()

  /**
   * Tracks the current sending status of the form
   * */
  const [contactFormStatus, setContactFormStatus] = React.useState<
    ContactFormStatus
  >(ContactFormStatus.NONE)

  /**
   * Create controlled components with the following state
   */
  const [nameInput, setName] = React.useState<string>("")
  const [nameError, setNameError] = React.useState<boolean>(false)

  const [companyInput, setCompany] = React.useState<string>("")
  const [companyError, setCompanyError] = React.useState<boolean>(false)

  const [phoneInput, setPhone] = React.useState<string>("")
  const [phoneError, setPhoneError] = React.useState<boolean>(false)

  const [emailInput, setEmail] = React.useState<string>("")
  const [emailError, setEmailError] = React.useState<boolean>(false)

  const [messageInput, setMessage] = React.useState<string>("")
  const [messageError, setMessageError] = React.useState<boolean>(false)

  /**
   * checkRequiredFields confirms all required fields have a value
   * */
  const checkRequiredFields = () => {
    let success = true
    if (!nameInput.trim()) {
      success = false
      setNameError(true)
    }
    if (!emailInput.trim()) {
      success = false
      setEmailError(true)
    }
    if (!messageInput.trim()) {
      success = false
      setMessageError(true)
    }

    return success
  }

  /**
   * submitForm sends the form to HubSpot if all fields are valid
   */
  const submitForm = () => {
    setContactFormStatus(ContactFormStatus.SENDING)

    /**
     * Validates required fields prior to sending form to hubspot
     */
    if (checkRequiredFields()) {
      const url = `https://forms.hubspot.com/uploads/form/v2/${hubspotInfo.portalId}/${hubspotInfo.guid}`
      const formBody: any = new FormData()
      formBody.append(name.key, nameInput.trim())
      formBody.append(company.key, companyInput.trim())
      if (phoneInput.trim()) formBody.append(phone.key, phoneInput.trim())
      formBody.append(email.key, emailInput.trim())
      formBody.append(message.key, messageInput.trim())

      fetch(url, {
        method: "POST",
        mode: "no-cors",
        body: formBody,
      })
        .then((response: any): void => {
          if (response && response.status < 300) {
            setContactFormStatus(ContactFormStatus.SUCCESS)
          } else {
            throw new Error(response.statusText)
          }
        })
        .catch((error): void => {
          if (error && error.message.toLowerCase() === "failed to fetch") {
            // We get this error because of CORS but the request actually went through so we can ignore this error
            setContactFormStatus(ContactFormStatus.SUCCESS)
          } else {
            setContactFormStatus(ContactFormStatus.FAILURE)
          }
        })
    } else {
      setContactFormStatus(ContactFormStatus.NONE)
    }
  }

  return (
    <section className="contact-us-wrapper">
      <InputField
        key={name.key}
        keyName={name.key}
        label={useTranslation(name.label)}
        placeholder={useTranslation(name.placeholder)}
        val={nameInput}
        setVal={setName}
        removeErrorMessage={setNameError}
        error={nameError}
        requiredFieldError={useTranslation(requiredFieldError)}
      />
      <InputField
        key={email.key}
        keyName={email.key}
        label={useTranslation(email.label)}
        placeholder={useTranslation(email.placeholder)}
        val={emailInput}
        setVal={setEmail}
        removeErrorMessage={setEmailError}
        error={emailError}
        requiredFieldError={useTranslation(requiredFieldError)}
      />
      <InputField
        key={company.key}
        keyName={company.key}
        label={useTranslation(company.label)}
        placeholder={useTranslation(company.placeholder)}
        val={companyInput}
        setVal={setCompany}
        removeErrorMessage={setCompanyError}
        error={companyError}
        requiredFieldError={useTranslation(requiredFieldError)}
      />
      <InputField
        key={phone.key}
        keyName={phone.key}
        label={useTranslation(phone.label)}
        placeholder={useTranslation(phone.placeholder)}
        val={phoneInput}
        setVal={setPhone}
        removeErrorMessage={setPhoneError}
        error={phoneError}
        requiredFieldError={useTranslation(requiredFieldError)}
      />
      <TextAreaField
        key={message.key}
        label={useTranslation(message.label)}
        placeholder={useTranslation(message.placeholder)}
        val={messageInput}
        setVal={setMessage}
        removeErrorMessage={setMessageError}
        error={messageError}
        requiredFieldError={useTranslation(requiredFieldError)}
      />
      <button
        type="button"
        onClick={submitForm}
        disabled={contactFormStatus === ContactFormStatus.SENDING}
      >
        {contactFormStatus === ContactFormStatus.SENDING
          ? useTranslation(sendingMessageBtnText)
          : useTranslation(sendMessageBtnText)}
      </button>
      {contactFormStatus === ContactFormStatus.SUCCESS && (
        <div className="success-message">
          <AiOutlineCheckCircle className="large-icon" />
          <span>{useTranslation(successMessage)}</span>
        </div>
      )}
      {contactFormStatus === ContactFormStatus.FAILURE && (
        <div className="error-message">
          <AiOutlineWarning className="large-icon" />
          <span>{useTranslation(failureMessage)}</span>
        </div>
      )}
    </section>
  )
}

export default ContactUs
