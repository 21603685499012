import * as React from "react"
import useLocalizationKeys from "../localization-utils/useLocalizationKeys"
import useTranslation from "../localization-utils/useTranslation"

interface TextAreaFieldProps {
  label: string
  placeholder: string
  val: string
  error: boolean
  requiredFieldError: string
  setVal: (arg0: string) => any
  removeErrorMessage: (arg0: boolean) => any
}

const TextAreaField: React.FC<TextAreaFieldProps> = ({
  label,
  setVal,
  val,
  placeholder,
  error,
  removeErrorMessage,
  requiredFieldError,
}: TextAreaFieldProps): JSX.Element => {
  /**
   * updateVal is invoked when user changes input field. This removes any error message and saves the changed value in state.
   * @param event
   */
  const updateVal = (event: React.SyntheticEvent): void => {
    removeErrorMessage(false)
    setVal((event.target as HTMLTextAreaElement).value)
  }

  return (
    <>
      <div className="input-textarea-with-label">
        <label htmlFor={label}>{label}</label>
        <textarea
          className={error ? "error-field" : ""}
          placeholder={placeholder}
          name={label}
          onChange={updateVal}
          value={val}
        />
      </div>
      {error && <p className="error-text">{requiredFieldError}.</p>}
    </>
  )
}

export default TextAreaField
